<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
                <Form label-position="left" :label-width="80" >
                    <FormItem label="id" v-if="data.id">
                        <Input v-model="data.id"  disabled></Input>
                    </FormItem>

                    <FormItem label="英雄id" >
                        <Input v-model="data.hero_id"  disabled></Input>
                    </FormItem>

                    <FormItem v-for="(item, index) in keys" :label="item.desc" :index="item.key" :key="item.key">
                        <Row>
                            <Col span="20" >
                                <InputNumber v-model="data[item.key]" :min='0' ></InputNumber>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="删除">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.deleted" >
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
            </TabPane>
        </Tabs>

        <Row :gutter="16" align="middle">
            <Col span="12" style="text-align: left" >
                <Button type="default"  @click="cancle" long size="default">取消</Button>
            </Col>
            <Col span="12" style="text-align: right">
                <Button type="primary"  @click="save" long size="default">保存</Button>
            </Col>
        </Row>
    </div>
</template>
<script>

import { getHeroAttr, editHeroAttr } from '@/api/games/hero_attr';

export default {
    name: "hero-attr-edit",
    props: {
        game_id: Number,   // 运动id
        attr_id: Number,   // 比赛id
        hero_id: Number,   // 英雄id
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 1,
            type: 5, 
            spinShow: false,
            data: {
                hero_id: this.hero_id,
            },
            keys : [],
        }
    },
    methods: {
        _init_data(){
            // console.log("hero_id", this.hero_id);
            this.data = {
                hero_id: this.hero_id,
            }
        },
        // 获取比赛的基础数据
        getData () {
            let self = this;
            this.spinShow = true;
            // 搜索日期
            if (self.attr_id > 0){
                var params = {
                    game_id: self.game_id,
                    attr_id: self.attr_id,
                }
                getHeroAttr( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self._init_data();
            }
            self.spinShow = false;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            self.emit("cancle")
        },
        save() {
            let self = this;
            var params = self.data;
            params['game_id'] = self.game_id;
            // console.log(params); self.emit("save")
            editHeroAttr(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
        // end 
        init_keys(){
            let self = this;
            if (self.game_id == 1){
                self.keys = [
                    {"key": 'baof', 'desc': '爆发'},
                    {"key": 'kongz', 'desc': '控制'},
                    {"key": 'youz', 'desc': '游走'},
                    {"key": 'day', 'desc': '打野'},
                    {"key": 'tuij', 'desc': '推进'},
                    {"key": 'houq', 'desc': '后期'},
                ]
            }else if (self.game_id == 3){
                self.keys = [
                    {"key": 'hex', 'desc': '核心'},
                    {"key": 'fuz', 'desc': '辅助'},
                    {"key": 'naij', 'desc': '耐久'},
                    {"key": 'kongz', 'desc': '控制'},
                    {"key": 'xians', 'desc': '先手'},
                    {"key": 'baof', 'desc': '爆发'},
                    {"key": 'taos', 'desc': '逃生'},
                    {"key": 'day', 'desc': '打野'},
                    {"key": 'tuij', 'desc': '推进'},
                ]
            }else{
                self.keys = [];
            }
        },
        // end 
    },
    mounted () {
    },
    watch: {
        'attr_id': {
            handler(value){
                let self = this;
                self.init_keys();
                // console.log("Detail Get Data", value);
                self.getData();
            },
            deep: false,
	        immediate: false,  // 深度触发
        },
        // end 
        'hero_id': {
            handler(value){
                let self = this;
                self.init_keys();
                self.data.hero_id = value;
                // console.log("Detail Get Data hero_id", value);
            },
            deep: false,
	        immediate: false,  // 深度触发
        },
    },
    computed: {
        
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>