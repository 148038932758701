<template>
    <div>
        <heroList :game_id="game_id"></heroList>
    </div>
</template>

<script>

import heroList from '@/components/games/hero/list.vue';

export default {
    components: {
        heroList
    },
    data () {
        let self = (window.x = this)
        return {
            game_id: 1,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>