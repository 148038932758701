import http from '@/utils/http';


export const getHeroAttr = (parameter) => {
  return http.request({
    url: '/v1/games/hero_attr/get',
    method: 'get',
    params: parameter
  })
}

export const editHeroAttr = (parameter) => {
  return http.request({
    url: '/v1/games/hero_attr/edit',
    method: 'post',
    data: parameter
  })
}
