<template>
    <div>
        <Drawer
            v-model="drawer_edit"
            width="500"
            :mask-closable="true"
        >
            <heroEdit :game_id="game_id" :hero_id="edit_id" @closePage="closeEditDrawer"></heroEdit>
        </Drawer>  

        <Drawer
            v-model="drawer_attr_edit"
            width="500"
            :mask-closable="true"
        >
            <heroAttrEdit :game_id="game_id" :attr_id="edit_attr_id" :hero_id="edit_attr_hero_id" @closePage="closeEditAttrDrawer"></heroAttrEdit>
        </Drawer>  

        <Row style="margin-bottom:10px;">
            <Col span="4" style="text-align: left">
                <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
            </Col>
            <!-- <Col style="text-align: right">
                <Checkbox v-model="deleted" border>筛选删除</Checkbox> 
            </Col> -->
            <Col >
                <Button type="success"  @click="add">新增</Button>
            </Col>
        </Row>
        <fitTable 
            :columns="columns" 
            :data="data" 
            :loading="loading" 
        >
        </fitTable>
        <Page
            :total="total_page"
            :current="current_page"
            :page-size="page_size"
            @on-change="onPageChange"
            show-total
            show-elevator
        ></Page>
    </div>
</template>
<script>

import { getHeros } from '@/api/games/hero';
// import moment from 'moment';
import heroEdit from './edit.vue';
import {getImgGameUrl} from '@/utils/util';
import fitTable from '../../basic/table.vue';
import heroAttrEdit from '../hero_attr/edit.vue';

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id);
                // 删除本行
                vm.data.splice(params.index, 1)
            }
        }
        },
        [
        h(
            'Button',
            {
                style: {
                    margin: '2px 2px'
                },
                props: {
                    type: 'warning',
                    size: 'small'
                }
            },
            '删除'
        )
        ]
    )
}

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                // type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

const editAttrButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                // type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_attr_id = params.row.attr? params.row.attr.id: 0;
                    vm.edit_attr_hero_id = params.row.attr? params.row.attr.hero_id || params.row.id: params.row.id;
                    vm.drawer_attr_edit = true;
                    // console.log(vm.edit_attr_hero_id)
                }
            }
        },
        '编辑属性'
    )
}

export default {
    name: "hero-list",
    props: {
        game_id: Number,   // 运动id
    },
    components: {
        heroEdit,  // 编辑
        fitTable,
        heroAttrEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            edit_attr_id: 0,  // 属性id
            drawer_attr_edit: false,  // 属性 模态框
            edit_attr_hero_id: 0,

            deleted: false,
            drawer_edit: false,
            edit_id : 0,

            search_key: '',

            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 100,
                    key: 'id',
                },
                {
                    title: '中文名称',
                    minWidth: 80,
                    align: 'left',
                    key: 'name_zh',
                },
                {
                    title: '英文名称',
                    minWidth: 80,
                    align: 'left',
                    key: 'name_en',
                },
                {
                    title: '中文简称',
                    minWidth: 80,
                    align: 'left',
                    key: 'abbr_zh',
                },
                {
                    title: '英文简称',
                    minWidth: 80,
                    align: 'left',
                    key: 'abbr_en',
                },
                {
                    title: 'logo',
                    key: 'logo',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let logo = currentRow.logo;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'hero', logo), shape: 'square', size: 'middle' },
                                class: {'hero-logo': true} })],
                        ));
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },

                {
                    title: 'vert-logo',
                    key: 'vert_logo',
                    align: 'center',
                    minWidth: 80,
                    game_ids: [3,],
                    render: function (h, params) {
                        let currentRow = params.row;
                        let vert_logo = currentRow.vert_logo;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'hero/vert', vert_logo), shape: 'square', size: 'middle' },
                                class: {'hero-logo': true} })],
                        ));
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },

                {
                    title: 'vertical-logo',
                    key: 'vertical_logo',
                    align: 'center',
                    minWidth: 80,
                    game_ids: [1,],
                    render: function (h, params) {
                        let currentRow = params.row;
                        let vert_logo = currentRow.vertical_logo;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'hero/vertical', vert_logo), shape: 'square', size: 'middle' },
                                class: {'hero-logo': true} })],
                        ));
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },

                {
                    title: 'icon',
                    key: 'icon',
                    align: 'center',
                    minWidth: 80,
                    game_ids: [3,],
                    render: function (h, params) {
                        let currentRow = params.row;
                        let icon = currentRow.icon;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'hero/icon', icon), shape: 'square', size: 'middle' },
                                class: {'hero-logo': true} })],
                        ));
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },

                {
                    title: '属性',
                    key: 'attr',
                    align: 'center',
                    minWidth: 100,
                    game_ids: [1,],
                    render: function (h, params) {
                        let currentRow = params.row;
                        let attr = currentRow.attr;
                        return h("Row", [
                            h("Col", '爆发' + attr.baof + ', ' ),
                            h("Col", '控制' + attr.kongz + ', '),
                            h("Col", '游走' + attr.youz + ', '),
                            h("Col", '打野' + attr.day + ', '),
                            h("Col", '推进' + attr.tuij + ', '),
                            h("Col", '后期' + attr.houq + ', '),
                        ])
                    }
                },

                {
                    title: '属性',
                    key: 'attr',
                    align: 'center',
                    minWidth: 100,
                    game_ids: [3,],
                    render: function (h, params) {
                        let currentRow = params.row;
                        let attr = currentRow.attr;
                        return h("Row", [
                            h("Col", '核心' + attr.hex + ', ' ),
                            h("Col", '辅助' + attr.fuz + ', '),
                            h("Col", '耐久' + attr.naij + ', '),
                            h("Col", '控制' + attr.kongz + ', '),
                            h("Col", '先手' + attr.xians + ', '),
                            h("Col", '爆发' + attr.baof + ', '),
                            h("Col", '逃生' + attr.taos + ', '),
                            h("Col", '打野' + attr.day + ', '),
                            h("Col", '推进' + attr.tuij + ', '),
                        ])
                    }
                },
                // {
                //     title: '被删除',
                //     key: 'deleted',
                //     align: 'center',
                //     minWidth: 80,
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         let deleted = currentRow.deleted;
                //         let deleted_str = deleted == 0 ? '正常': (
                //             deleted == 1 ? '被删除': '未知:'+deleted
                //         )
                //         const deleted_status = deleted == 0 ? 'success': (
                //             deleted == 1 ? 'error': 'default:'
                //         )
                //         return [h( 'Badge', {attrs: {status: deleted_status,  }} ), 
                //                 h('span', deleted_str)]
                //     }
                // },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 180,
                    render (h, params) {
                        return [editButton(self, h, params), editAttrButton(self, h, params)]  // delButton(self, h, params)
                    }
                }
            ],
            data: [],
            drawer_styles: {
                padding: '2px',
            },
            // 编辑
        }
    },
    methods: {
        onPageChange (page) {
            // console.log("1 change", page);
            this.current_page = page;
            this.getData()
        },
        onSearch (search_key) {
            // console.log("2 change", search_key);
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit = false;
            self.edit_id = 0;
            self.getData();
        },
        closeEditAttrDrawer(value){
            let self = this;
            self.edit_attr_id = 0;  // 属性id
            self.drawer_attr_edit = false;  // 属性 模态框
            self.edit_attr_hero_id = 0;
            if (value == 'save'){
                self.getData();
            }
        },
        // end
        getData (status_ids) {
            let self = this;
            this.loading = true
            // 搜索日期
            let lang = sessionStorage.getItem("lang");
            // 搜索状态
            if (status_ids){
            }else{
                if (this.status_ids){
                    // console.log(this.status_ids, typeof(this.status_ids) );
                    var status_ids = this.status_ids.join(',');
                }else{
                    var status_ids = '';
                }
            }
            //
            if (self.end_date > 0 && self.end_date === self.start_date){
                self.end_date = self.end_date + 3600 * 24 - 1;
            } 
            var params = {
                game_id: this.game_id,
                search_key: this.search_key,
                page: this.current_page,
                lang: lang,
            }
            if (self.deleted){
                params['deleted'] = 1;
            }
            getHeros(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list
                    this.total_page = response.data.data.total_page
                    this.page_size = response.data.data.page_size
                    this.current_page = response.data.data.current_page
                    this.loading = false
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        
        
        add () {
            this.edit_id = 0;
            this.drawer_edit = true
        },
        delete (_id) {
            var params = {
                id: _id,
                type: this.data_type
            }
            // console.log(params);
            deleteData(params).then(response => {
                if (response.data.code == 0) {
                    return true
                } else {
                    this.$Message.error(response.data.msg)
                    return false
                }
            })
        },
        // end 

    },
    mounted () {
        this.$nextTick(function () {
            this.columns = this.columns.filter(col => {
                if (col.game_ids && col.game_ids.length > 0){
                    return col.game_ids.indexOf( this.game_id ) > -1 ;
                } else{
                    return true;
                }
            });
            this.getData();
        })
    },
    watch: {
        // status_ids (value){
        // },
        deleted(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        }
    },
    computed: {}
}
</script>
<style scoped>
    @import "list.css";
</style>