<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
                <Form label-position="left" :label-width="80" >
                    <FormItem label="id" v-if="data.id">
                        <Input v-model="data.id"  disabled></Input>
                    </FormItem>

                    <FormItem label="中文">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.name_zh" ></Input>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem label="英文">
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.name_en" ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="中文简称" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.abbr_zh" ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="英文简称" >
                        <Row>
                            <Col span="20" >
                                <Input v-model="data.abbr_en" ></Input>
                            </Col>
                        </Row>
                    </FormItem>

                    <FormItem label="logo: ">
                        <Row>
                            <imgEditor v-model="data.logo" :prefix="$conf.games.img_prefix" :dir="$conf.games.games[game_id].img + '/hero' " 
                                :previewButton='true' :clearButton='true' cdn='games'>
                            </imgEditor>
                        </Row>
                    </FormItem>

                    <FormItem label="vert-logo: " v-if=" $unit.filterGameids( [3,], game_id )">
                        <Row>
                            <imgEditor v-model="data.vert_logo" :prefix="$conf.games.img_prefix" :dir="$conf.games.games[game_id].img + '/hero/vert' " 
                                :previewButton='true' :clearButton='true' cdn='games'>
                            </imgEditor>
                        </Row>
                    </FormItem>
                    <FormItem label="vertical-logo: " v-if=" $unit.filterGameids( [1,], game_id )">
                        <Row>
                            <imgEditor v-model="data.vertical_logo" :prefix="$conf.games.img_prefix" :dir="$conf.games.games[game_id].img + '/hero/vertical' " 
                                :previewButton='true' :clearButton='true' cdn='games'>
                            </imgEditor>
                        </Row>
                    </FormItem>

                    <FormItem label="icon: " v-if=" $unit.filterGameids( [3,], game_id )">
                        <Row>
                            <imgEditor v-model="data.icon" :prefix="$conf.games.img_prefix" :dir="$conf.games.games[game_id].img + '/hero/icon' " 
                                :previewButton='true' :clearButton='true' cdn='games'>
                            </imgEditor>
                        </Row>
                    </FormItem>
                    
                    <FormItem label="删除">
                        <Row>
                            <Col span="20" >
                                <Select v-model="data.deleted" >
                                    <Option :value="1" key="1">是</Option>
                                    <Option :value="0" key="0">否</Option>
                                </Select>
                            </Col>
                        </Row>
                    </FormItem>
                </Form>
            </TabPane>
        </Tabs>

        <Row :gutter="16" align="middle">
            <Col span="12" style="text-align: left" >
                <Button type="default"  @click="cancle" long size="default">取消</Button>
            </Col>
            <Col span="12" style="text-align: right">
                <Button type="primary"  @click="save" long size="default">保存</Button>
            </Col>
        </Row>
    </div>
</template>
<script>

import { getHeros, editHero } from '@/api/games/hero';
import imgEditor from '@/components/img-editor/img-editor.vue';

export default {
    name: "hero-edit",
    props: {
        game_id: Number,   // 运动id
        hero_id: Number,   // 比赛id
    },
    components: {
        imgEditor,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 1,
            type: 5, 
            spinShow: false,
            data: {
                game_id: self.game_id,
                country: {},
            },
        }
    },
    methods: {
        _init_data(){
            this.data = {
                game_id: this.game_id,
                country: {},
            }
        },
        // 获取比赛的基础数据
        getData () {
            let self = this;
            this.spinShow = true;
            // 搜索日期
            if (self.hero_id > 0){
                var params = {
                    game_id: self.game_id,
                    hero_id: self.hero_id,
                }
                getHeros( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self._init_data();
            }
            self.spinShow = false;
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        cancle(){
            let self = this;
            self.emit("cancle")
        },
        save() {
            let self = this;
            if (self.game_id == 1){
                // lol
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    name_en: self.data.name_en,
                    name_zh: self.data.name_zh,
                    abbr_en: self.data.abbr_en,
                    abbr_zh: self.data.abbr_zh,
                    logo: self.data.logo,
                    vertical_logo: self.data.vertical_logo,
                    deleted: self.data.deleted,
                };
            }else if (self.game_id == 2){
                // 地图池
                return
            }else if (self.game_id == 3){
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    name_en: self.data.name_en,
                    name_zh: self.data.name_zh,
                    short_name_en: self.data.abbr_en,
                    short_name_zh: self.data.abbr_zh,
                    logo: self.data.logo,
                    vert_logo: self.data.vert_logo,
                    icon: self.data.icon ,
                    deleted: self.data.deleted,
                };
            }else if (self.game_id == 6){
                var params = {
                    id: self.data.id,
                    game_id: self.data.game_id || self.game_id,
                    name_en: self.data.name_en,
                    name_zh: self.data.name_zh,
                    short_name_en: self.data.abbr_en,
                    short_name_zh: self.data.abbr_zh,
                    logo: self.data.logo,
                    deleted: self.data.deleted,
                };
            }else{
                return
            }
            // console.log(params);
            editHero(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
    },
    watch: {
        'hero_id': {
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
            deep: false,
	        immediate: false,  // 深度触发
        },
        // end 
    },
    computed: {
        
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>